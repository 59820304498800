/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import React, { Component } from 'react';
import { MetaTags } from 'react-meta-tags';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import Result from 'iotera-base/result';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  ModalBody,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { isNumber } from 'iotera-base/utility/object';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  list,
  list_balance,
  toggleRefund,
  manualUpdate,
  addProjectHandle
} from '../../store/Mapping/actions';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { create } from '../../store/Project/actions';
import { keys } from 'lodash';
import { set_price_subs } from '../../store/business-point-fee/actions';
class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      modalPrice: false,
      modalProjectHandle: false,
      rows1: [],
      list: [],
      ProjectListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'App ID',
          dataField: 'appid',
          sort: true,
          editable: false,
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          formatter: (cellContent, row) => <>{row.appid}</>,
        },
        {
          text: 'Application Name',
          dataField: 'name',
          sort: true,
          editable: false,
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  to="#"
                  className="text-dark"
                  onClick={() => window.open(safeDeepGet(row, 'url_cms', ''))}
                >
                  {row.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          text: 'Balance',
          dataField: 'balance',
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          sort: true,
          editable: false,
          formatter: (cellContent, row) => (
            <>
              <p
                className="mb-2"
                style={{
                  textAlign: 'right',
                  color:
                    Number(safeDeepGet(row, 'balance', 0)) < 0
                      ? 'red'
                      : 'black',
                }}
              >
                {row.balance != 'Loading....'
                  ? 'Rp. ' +
                    Number(safeDeepGet(row, 'balance', 0))
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
                    ',-'
                  : safeDeepGet(row, 'balance', 0)}
              </p>
              <p
                className="mb-0"
                style={{
                  textAlign: 'right',
                  color: 'black',
                }}
              >
                {'Latest Update: ' +
                  this.handleTime(safeDeepGet(row, 'commit_point', 0))}
              </p>
            </>
          ),
        },
        {
          text: 'Subscription Price',
          dataField: 'price',
          editable: false,
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p
                className="mb-2"
                style={{
                  textAlign: 'right',
                  color:
                    Number(safeDeepGet(row, 'price', 0)) < 0 ? 'red' : 'black',
                }}
              >
                {'Rp. ' +
                  Number(safeDeepGet(row, 'price', 0))
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') +
                  ',-'}
              </p>
            </>
          ),
        },
        {
          dataField: 'autoRefund',
          text: 'Auto Refund',
          type: 'bool',
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false',
          },
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {row.autoRefund ? 'ON' : 'OFF'}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'manualUpdate',
          text: 'Update Balance',
          isDummyField: true,
          editable: false,
          headerAlign: (column, colIndex) => 'center',
          align: 'center',
          formatter: (cellContent, row) => (
            <>
              <Button
                className="btn btn-primary white"
                onClick={() => this.handleManualUpdateBalance(row)}
              >
                Update
              </Button>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          align: 'center',
          text: 'Action',
          headerAlign: (column, colIndex) => 'center',
          formatter: (cellContent, data) => (
            <div className="d-flex gap-3">
              <Tippy content={'User'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-account font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleUserClick(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Device'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-devices font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleDeviceClick(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Tags'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-tag font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleTagClick(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Graph'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-chart-box font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleGraphClick(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Summary'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-microsoft-excel font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleSummaryClick(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Price'}>
                <Link className="text-info" to="#">
                  <i
                    className="mdi mdi-bank font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleChangePrice(data)}
                  ></i>
                </Link>
              </Tippy>
              <Tippy content={'Download CSV'}>
                <Link className="text-success" to="#">
                  <i
                    className="mdi mdi-download font-size-18"
                    id="edittooltip"
                    onClick={() => this.handleDonloadClick(data)}
                  ></i>
                </Link>
              </Tippy>
            </div>
          ),
        },
      ],
    };
    this.handleValidSubmitPrice = this.handleValidSubmitPrice.bind(this);
    this.handleValidProjectHandle = this.handleValidProjectHandle.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleAddProjectHandle = this.handleAddProjectHandle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGraphClick = this.handleGraphClick.bind(this);
    this.handleSummaryClick = this.handleSummaryClick.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleDeviceClick = this.handleDeviceClick.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.handleDonloadClick = this.handleDonloadClick.bind(this);
    this.togglePrice = this.togglePrice.bind(this);
    this.afterSaveCell = this.afterSaveCell.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleManualUpdateBalance = this.handleManualUpdateBalance.bind(this);
  }
  handleTime(time) {
    const date1 = new Date(time).toLocaleDateString('en-GB');
    const time1 = new Date(time).toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  }
  componentDidMount() {
    this.props.MappList();
  }
  handleChangePrice = row => {
    this.setState({
      applicationID: '100000' + row.id,
      appId: row.id,
      price_subs: row.price,
    });
    this.togglePrice();
  };
  togglePrice() {
    this.setState(prevState => ({
      modalPrice: !prevState.modalPrice,
      // isEdit: !prevState.isEdit,
    }));
  }
  handleValidSubmitPrice(event, values) {
    const { appId } = this.state;
    const price_subs = Number(values.price_subs);
    const submittedValues = {
      appId,
      price_subs,
    };
    // console.log(submittedValues)
    this.props.UpdatePrice(submittedValues);
  }
  handleValidProjectHandle(event, values) {
    this.props.AddProjectHandle(values);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.addResult !== prevProps.addResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['addResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast();
        this.handleAddProjectHandle();
        this.props.MappList();
      } else if (code === Result.FAILED) {
        this.showToastFailed();
        this.props.MappList();
      }
    }
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast();
        this.toggleDetail();
      } else if (code === Result.FAILED) {
        this.showToastFailed();
        this.props.MappList();
      }
    }
    if (this.props.result !== prevProps.result) {
      const list = safeDeepGet(this.props, ['result', 'body'], []);
      if (list.length > 0) {
        this.props.BalanceList();
        this.setState({ list });
      }
    }
    if (this.props.balance_result !== prevProps.balance_result) {
      const list = safeDeepGet(this.props, ['balance_result', 'body'], []);
      if (list.length > 0) {
        this.setState({ list });
      }
    }

    if (this.props.setPriceResult !== prevProps.setPriceResult) {
      const code = safeDeepGet(
        this.props,
        ['setPriceResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.togglePrice();
        // this.props.MappList();
        this.props.BalanceList();
      }
    }
    if (this.props.toggleResult !== prevProps.toggleResult) {
      const code = safeDeepGet(
        this.props,
        ['toggleResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        this.props.BalanceList();
        this.showToastToggle();
      }
    }
    if (this.props.manualResult !== prevProps.manualResult) {
      const code = safeDeepGet(
        this.props,
        ['manualResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        this.props.BalanceList();
        this.showToastToggle();
      }
    }
  }
  showToastFailed() {
    var toastType = 'error';
    var message = 'Gagal Tambah Project';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastToggle() {
    var toastType = 'success';
    var message = 'Berhasil merubah kondisi';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Tambah Project';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  handleImportClick = row => {
    this.toggleDetail();
  };
  handleAddProjectHandle = row => {
    this.setState(prevState => ({
      modalProjectHandle: !prevState.modalProjectHandle,
    }));
  };

  handleManualUpdateBalance = data => {
    this.props.ManualUpdateBalance(data);
  };
  handleGraphClick = row => {
    const appId = safeDeepGet(row, 'appid', '');
    this.props.history.replace('/admin/graph/' + appId);
  };
  handleSummaryClick = row => {
    const appId = safeDeepGet(row, 'appid', '');
    this.props.history.replace('/admin/summary/' + appId);
  };
  handleDonloadClick = data => {
    const id = safeDeepGet(data, 'id', '-');
    const arr_data = [];
    for (const i in data) {
      const url = data[i];
      if (i != 'balance') {
        arr_data.push({ keys: i, url: url.toString() });
      }
    }
    var csv = Papa.unparse(arr_data);

    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, 'download.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'Mapping_URL_Project_' + id + '.csv');
    tempLink.click();
  };
  handleOnChange = e => {
    const file = e.target.files[0];
    if (file.size > 5000) {
      this.setState({ file });
    } else {
      this.setState({ file });
    }
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        for (const i in results) {
          const valuesArray = results['data'];
          this.setState({ valuesArray });
        }
        const rowsArray = [];
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
        });
        this.setState({ rowsArray });
      }.bind(this),
    });
  };
  handleSubmit() {
    const payload = {
      data: this.state.valuesArray,
    };
    this.props.CreateCSV(payload);
    this.setState({ valuesArray: [] });
  }
  handleUserClick(project) {
    this.props.history.push('/admin/user/' + project.appid);
  }
  handleDeviceClick(project) {
    this.props.history.push('/admin/business-point/' + project.appid);
  }
  handleTagClick(project) {
    this.props.history.push('/admin/tags/' + project.appid);
  }
  afterSaveCell(oldValue, newValue, row, column) {
    const url_toggle_auto = safeDeepGet(row, 'url_toggle_auto_refund', '');
    const multitenancy = safeDeepGet(row, 'multitenancy', '');
    const app_id = safeDeepGet(row, 'app_id', '');
    if (url_toggle_auto != '') {
      const payload = {
        url: url_toggle_auto,
        application_id: '100000' + app_id,
        multitenancy: multitenancy,
        active: newValue,
        key: 'auto_refund',
      };
      this.props.ToogleRefund(payload);
    }
  }
  render() {
    // const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { list } = this.state;
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 100,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'email', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];
    const formValues = [
      { keys: 'id', url: ' ' },
      { keys: 'name', url: ' ' },
      { keys: 'url_balance', url: ' ' },
      { keys: 'url_bp_fee', url: ' ' },
      { keys: 'url_business_point', url: ' ' },
      { keys: 'url_call_scheduler', url: ' ' },
      { keys: 'url_cms_detail_chat', url: ' ' },
      { keys: 'url_cms_detail_complaint', url: ' ' },
      { keys: 'url_create_fee', url: ' ' },
      { keys: 'url_create_payout', url: ' ' },
      { keys: 'url_create_tag', url: ' ' },
      { keys: 'url_created_user', url: ' ' },
      { keys: 'url_data_chat', url: ' ' },
      { keys: 'url_delete_fee', url: ' ' },
      { keys: 'url_delete_tags', url: ' ' },
      { keys: 'url_deleted_user', url: ' ' },
      { keys: 'url_detail_transaction', url: ' ' },
      { keys: 'url_get_tag', url: ' ' },
      { keys: 'url_get_user', url: ' ' },
      { keys: 'url_latest_transaction', url: ' ' },
      { keys: 'url_refund_notification', url: ' ' },
      { keys: 'url_serverless_complaint', url: ' ' },
      { keys: 'url_summary', url: ' ' },
      { keys: 'url_summary_device', url: ' ' },
      { keys: 'url_update_time', url: ' ' },
    ];
    const arr_data = formValues;
    const headerKeys = Object.keys(Object.assign({}, ...arr_data));
    const dataheader = {};
    const header = [];
    for (const id in headerKeys) {
      const data = headerKeys[id];
      safeDeepSet(dataheader, [id, 'text'], data);
      safeDeepSet(dataheader, [id, 'dataField'], data);

      // set formatter text in table
      if (data == 'imgUrl') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.imgUrl}</p>
          </>
        ));
      }
      if (data == 'description') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.description}</p>
          </>
        ));
      }
      header.push(dataheader[id]);
    }

    const headers = [
      {
        label: 'keys',
        key: 'keys',
      },
      {
        label: 'url',
        key: 'url',
      },
    ];
    const separator = ','; //if want csv File using commma change with (',') or something else
    const csvLink = {
      headers: headers,
      data: formValues,
      separator: separator,
      filename: 'Projects.csv',
    };
    return (
      
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project List | Admin </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Project" breadcrumbItem="List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.ProjectListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.ProjectListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="2">
                                  <div className="text-sm-end">
                                    <Button
                                      className="btn btn-primary white "
                                      onClick={this.handleAddProjectHandle}
                                    >
                                      Add Project Handle
                                    </Button>
                                  </div>
                                </Col>
                                <Col xs="3">
                                  <div className="text-sm-end">
                                    <CSVLink
                                      {...csvLink}
                                      className="btn btn-primary white mb-3"
                                    >
                                      Download Template Mapping URL
                                    </CSVLink>
                                  </div>
                                </Col>
                                <Col xs="2">
                                  <div className="text-sm-start">
                                    <Button
                                      className="btn btn-primary white "
                                      onClick={this.handleImportClick}
                                    >
                                      Input CSV
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      cellEdit={cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true,
                                        afterSaveCell: this.afterSaveCell,
                                      })}
                                      // striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalDetail}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleDetail} tag="h4">
                        {'Import RFID'}
                      </ModalHeader>
                      <ModalBody>
                        <Row className="mb-3">
                          <Col xs="7">
                            <div className="text-sm-center">
                              <input
                                className="form-control"
                                type="file"
                                accept=".csv"
                                id="csvFile"
                                onChange={this.handleOnChange}
                              ></input>
                            </div>
                          </Col>
                          <Col xs="5">
                            <div className="text-sm-center">
                              <Button
                                className="btn btn-hamparan white "
                                onClick={this.handleSubmit}
                              >
                                Import CSV
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalProjectHandle}
                      className={this.props.className}
                    >
                      <ModalHeader
                        toggle={this.handleAddProjectHandle}
                        tag="h4"
                      >
                        {'Add Project'}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidProjectHandle}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Name Application :</Label>
                                        <AvField
                                          type="text"
                                          id="name"
                                          name="name"
                                          placeholder="Masukan Nama Aplikasi..."
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Application ID:</Label>
                                        <AvField
                                          type="text"
                                          id="applicationId"
                                          name="applicationId"
                                          placeholder="Masukan Application id..."
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-3 select2-container">
                                        <AvGroup check>
                                          <AvInput type="checkbox" name="multitenancy" />
                                          <Label check for="multitenancy"> Multitenancy</Label>
                                        </AvGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Firebase ID:</Label>
                                        <AvField
                                          type="text"
                                          id="id"
                                          name="id"
                                          placeholder="iotera-vending-multitenancy"
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Url:</Label>
                                        <AvField
                                          type="text"
                                          id="url"
                                          name="url"
                                          placeholder="iotera-vending-multitenancy"
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="10">
                                      <Label>..</Label>
                                      <FormGroup className="mb-3 select2-container">
                                        <AvField
                                          type="text"
                                          id="ffa"
                                          name="ffa"
                                          disabled={true}
                                          value="smartvending.cloud"
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.togglePrice}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button type="submit" color="primary">
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                    {/* Modal Price */}
                    <Modal
                      size="lg"
                      isOpen={this.state.modalPrice}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.togglePrice} tag="h4">
                        {'Subscription Price ' + this.state.applicationID}
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleValidSubmitPrice}>
                          <Row>
                            <Col xs="12">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-3 select2-container">
                                        <Label>Price Subscription:</Label>
                                        <AvField
                                          type="number"
                                          id="price_subs"
                                          name="price_subs"
                                          value={
                                            this.state.price_subs || 1000000
                                          }
                                          placeholder="Masukan harga Subscription..."
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              <div className="d-flex flex-wrap gap-2 float-end mb-4">
                                <Link
                                  className="btn btn-secondary"
                                  role="button"
                                  onClick={this.togglePrice}
                                  to="#"
                                >
                                  Batal
                                </Link>{' '}
                                <Button type="submit" color="primary">
                                  Simpan
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  test: state,
  result: safeDeepGet(state, ['Mapping', 'list'], {}),
  balance_result: safeDeepGet(state, ['Mapping', 'list_balance'], {}),
  createResult: safeDeepGet(state, ['Project', 'create'], {}),
  loading: safeDeepGet(state, ['Mapping', 'loading'], true),
  updateResult: safeDeepGet(state, ['Mapping', 'update'], {}),
  setPriceResult: safeDeepGet(state, ['BpFee', 'set_price_subs'], true),
  toggleResult: safeDeepGet(state, ['Mapping', 'toggleRefund'], {}),
  manualResult: safeDeepGet(state, ['Mapping', 'manualUpdate'], {}),
  addResult: safeDeepGet(state, ['Mapping', 'addProjectHandle'], {}),
});

const mapDispatchToProps = dispatch => ({
  MappList: () => dispatch(list()),
  BalanceList: () => dispatch(list_balance()),
  ToogleRefund: payload => dispatch(toggleRefund(payload)),
  ManualUpdateBalance: payload => dispatch(manualUpdate(payload)),
  // UpdateRefiller: payload => dispatch(update(payload)),
  // DeleteUser: payload => dispatch(delete_(payload)),
  CreateCSV: payload => dispatch(create(payload)),
  UpdatePrice: payload => dispatch(set_price_subs(payload)),
  AddProjectHandle: payload => dispatch(addProjectHandle(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
