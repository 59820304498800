/* eslint-disable semi */
/* Import */
import { init as initAdminFirebase } from 'Admin/firebase/helper';
// import { APPLICATION_ID } from 'Apps';
import { init as initBaseFirebase } from 'Base/firebase/helper';
import { init as initSvMilanoFirebase } from 'SvNestleMilano/firebase/helper';
import { init as initSvFrankeFirebase } from 'SvNestleFranke/firebase/helper';
import { init as initMultiTenancyFirebse } from 'Multitenancy/firebase/helper';
import { MULTITENANCY_HANDLE, APPLICATION_ID } from 'Apps';
import { safeDeepGet } from 'iotera-base/utility/json';
import { getProjectHandle } from 'Apps/device';
import Result from 'iotera-base/result';

export const init = async () => {
  console.log('APPLICATION_ID', APPLICATION_ID);
  if (MULTITENANCY_HANDLE) {
    if (
      !localStorage.getItem('application_id') ||
      localStorage.getItem('application_id') == ''
    ) {
      const baseUrl = "https://demo-multi.smartvending.cloud"
      // const baseUrl = window.location.href
      const split_baseUrl = baseUrl.split('//');
      const url_sub = split_baseUrl[1];
      const split_url_sub = url_sub.split('.');
      const project = split_url_sub[0];
      const resultww = await getProjectHandle();
      const code = safeDeepGet(resultww, 'code', Result.FAILED);
      if (code == Result.HTTP.OK) {
        const body = safeDeepGet(resultww, ['body', project], {});
        const projectBody = safeDeepGet(body, 'Project_handle', '');
        const app_idBody = safeDeepGet(body, 'Application_id', '');
        const multitenancyBody = safeDeepGet(body, 'Multitenancy', '');
        const multitenancyId = safeDeepGet(
          body,
          'Multitenancy_id',
          'iotera-vending-multitenancy'
        );
        localStorage.setItem('multitenancy_id', multitenancyId);
        localStorage.setItem('project_handle', projectBody);
        localStorage.setItem('application_id', app_idBody);
        localStorage.setItem('multitenancy', multitenancyBody);
        setTimeout(() => {
          location.reload();
        }, 500);
      } else {
        location.reload();
      }
    }
      
    if (
      localStorage.getItem('multitenancy_id') === "iotera-vending-multitenancy-2"
    ) {
      const multitenancyFirebaseConfig2 = {
        // TODO CHANGE WITH NEW FIREBASE
        apiKey: 'AIzaSyBK8mo9mjWD8W6bix9z7vhS6p-0VjFqgxo',
        authDomain: 'iotera-vending-multitenancy-2.firebaseapp.com',
        projectId: 'iotera-vending-multitenancy-2',
        storageBucket: 'iotera-vending-multitenancy-2.appspot.com',
        messagingSenderId: '87813007219',
        appId: '1:87813007219:web:d0ab8cff8967c845678706',
      };
      await initMultiTenancyFirebse(multitenancyFirebaseConfig2);
      return;
    } else {
      const multitenancyFirebaseConfig = {
        // TODO CHANGE WITH NEW FIREBASE
        apiKey: 'AIzaSyCmMLzycphAaCTwzDM68VpCh-OwDA29XHg',
        authDomain: 'iotera-vending-multitenancy.firebaseapp.com',
        projectId: 'iotera-vending-multitenancy',
        storageBucket: 'iotera-vending-multitenancy.appspot.com',
        messagingSenderId: '63658291827',
        appId: '1:63658291827:web:f5e9601bf7823b39887006',
        measurementId: 'G-STV94KGT5G',
      };
      await initMultiTenancyFirebse(multitenancyFirebaseConfig);
      return;
    }
  } else if (APPLICATION_ID == '1000000209') {
    const redboksFirebaseConfig = {
      apiKey: 'AIzaSyDMuC-8r2nW_cFPbxe_XC8749_92AzzfpA',
      authDomain: 'iotera-redboks.firebaseapp.com',
      projectId: 'iotera-redboks',
      storageBucket: 'iotera-redboks.appspot.com',
      messagingSenderId: '275761546194',
      appId: '1:275761546194:web:192475711921445fe97e0b',
      measurementId: 'G-R8C26M70DC',
    };
    initBaseFirebase(redboksFirebaseConfig);
  } else if (APPLICATION_ID == '1000000021') {
    const demoFirebaseConfig = {
      apiKey: 'AIzaSyBsA8J-wmJxsVAevnSmxT2paNwWl--tsWA',
      authDomain: 'iotera-vending.firebaseapp.com',
      projectId: 'iotera-vending',
      storageBucket: 'iotera-vending.appspot.com',
      messagingSenderId: '59288803131',
      appId: '1:59288803131:web:da3fbf32b9271cbf39091e',
      measurementId: 'G-ZX4BH95EM4',
    };

    initBaseFirebase(demoFirebaseConfig);
  } else if (APPLICATION_ID == 'Admin') {
    const adminFirebaseConfig = {
      apiKey: 'AIzaSyBsA8J-wmJxsVAevnSmxT2paNwWl--tsWA',
      authDomain: 'iotera-vending.firebaseapp.com',
      projectId: 'iotera-vending',
      storageBucket: 'iotera-vending.appspot.com',
      messagingSenderId: '59288803131',
      appId: '1:59288803131:web:da3fbf32b9271cbf39091e',
      measurementId: 'G-ZX4BH95EM4',
    };

    initAdminFirebase(adminFirebaseConfig);
  } else if (APPLICATION_ID == '1000000229') {
    const fujiFirebaseConfig = {
      apiKey: 'AIzaSyDzSG25GhosEs_TrgBjkuIO2UzoVPmwh_s',
      authDomain: 'sv-vendkiosk.firebaseapp.com',
      projectId: 'sv-vendkiosk',
      storageBucket: 'sv-vendkiosk.appspot.com',
      messagingSenderId: '851255906309',
      appId: '1:851255906309:web:44286ffdf74a87bae50c6c',
      measurementId: 'G-WP1CQQF11Q',
    };

    initBaseFirebase(fujiFirebaseConfig);
  } else if (APPLICATION_ID == '1000000159') {
    const Svnestle = {
      apiKey: 'AIzaSyDKorbzr8wNWzZ_FU5N5-xJULeLtU93cFA',
      authDomain: 'sv-nestle-milano.firebaseapp.com',
      projectId: 'sv-nestle-milano',
      storageBucket: 'sv-nestle-milano.appspot.com',
      messagingSenderId: '510208245496',
      appId: '1:510208245496:web:63a9890f3cef6b5daf050d',
      measurementId: 'G-JQJC70F5MK',
    };
    initSvMilanoFirebase(Svnestle);
  } else if (APPLICATION_ID == '1000000211') {
    const Svfranke = {
      apiKey: 'AIzaSyDi0opUyEpNbxy6TfhpdTXRkN_IlAsoV34',
      authDomain: 'iotera-nestle.firebaseapp.com',
      projectId: 'iotera-nestle',
      storageBucket: 'iotera-nestle.appspot.com',
      messagingSenderId: '843697806121',
      appId: '1:843697806121:web:41a4a452bb59e236a83538',
      measurementId: 'G-KB835NG6GH',
    };

    initSvFrankeFirebase(Svfranke);
  } else if (APPLICATION_ID == '1000000226') {
    const Indesso = {
      apiKey: 'AIzaSyBwXI_q3M-hZ9Jqhg0VZzEfUMylYfYcTE8',
      authDomain: 'iotera1-vending.firebaseapp.com',
      projectId: 'iotera1-vending',
      storageBucket: 'iotera1-vending.appspot.com',
      messagingSenderId: '825288993611',
      appId: '1:825288993611:web:f57bbe72bc879b2ae156ce',
    };

    initBaseFirebase(Indesso);
  } else if (APPLICATION_ID == '1000000232') {
    const Hamparan = {
      apiKey: 'AIzaSyC2Jl9Y-ZOgET4DDGzeXU_3pJM5proRXRI',
      authDomain: 'sv-hamparan-f48a1.firebaseapp.com',
      projectId: 'sv-hamparan-f48a1',
      storageBucket: 'sv-hamparan-f48a1.appspot.com',
      messagingSenderId: '1050354463272',
      appId: '1:1050354463272:web:29c7a8c2684f844adbbb1d',
      measurementId: 'G-8XCV60W557',
    };

    initBaseFirebase(Hamparan);
  } else if (APPLICATION_ID == '1000000233') {
    const TTM = {
      apiKey: 'AIzaSyDg-vQTDl3HraES49XhDsvTiWvGEYVnRmg',
      authDomain: 'sv-vendkiosk-ttm.firebaseapp.com',
      projectId: 'sv-vendkiosk-ttm',
      storageBucket: 'sv-vendkiosk-ttm.appspot.com',
      messagingSenderId: '652481504045',
      appId: '1:652481504045:web:2d9ea32158297bedd1e9a4',
      measurementId: 'G-VXK78SQY23',
    };

    initBaseFirebase(TTM);
  } else if (APPLICATION_ID == '1000000245') {
    const Wapo = {
      apiKey: 'AIzaSyDJSU7qC504w8rlIZ7A4ez8iwcjVzU9_U4',
      authDomain: 'sv-hamparan-inaco.firebaseapp.com',
      projectId: 'sv-hamparan-inaco',
      storageBucket: 'sv-hamparan-inaco.appspot.com',
      messagingSenderId: '208622362589',
      appId: '1:208622362589:web:e29a658924b57e6f1d6034',
      measurementId: 'G-XY2QQSQ8L4',
    };

    initBaseFirebase(Wapo);
  } else if (APPLICATION_ID == '1000000246') {
    const Freshtin = {
      apiKey: 'AIzaSyCBkV2Hp6mcHicLcXynwSlZ5Q1mfnRcyrk',
      authDomain: 'sv-freshtin.firebaseapp.com',
      projectId: 'sv-freshtin',
      storageBucket: 'sv-freshtin.appspot.com',
      messagingSenderId: '1016944256282',
      appId: '1:1016944256282:web:af5e60ad404c0d272a78a3',
    };

    initBaseFirebase(Freshtin);
  } else if (APPLICATION_ID == '1000000240') {
    const Mandarin = {
      apiKey: 'AIzaSyDrq4e1oj8E6a6HQ4xoEQDpVBDz4CxQlUw',
      authDomain: 'sv-mandarin-sentosa.firebaseapp.com',
      projectId: 'sv-mandarin-sentosa',
      storageBucket: 'sv-mandarin-sentosa.appspot.com',
      messagingSenderId: '647410598573',
      appId: '1:647410598573:web:e06a546ae5e9f40ea1bc24',
      measurementId: 'G-FXZ4DPZPRE',
    };

    initBaseFirebase(Mandarin);
  } else if (APPLICATION_ID == '1000000250') {
    const Wondermart = {
      apiKey: 'AIzaSyB7epAeZmpoCJxNOH7ES5ymYScdupZvnxQ',
      authDomain: 'sv-wondermart.firebaseapp.com',
      projectId: 'sv-wondermart',
      storageBucket: 'sv-wondermart.appspot.com',
      messagingSenderId: '400969390516',
      appId: '1:400969390516:web:6c29463a00dc284d923ae1',
      measurementId: 'G-M20ZK5NDBB',
    };

    initBaseFirebase(Wondermart);
  } else if (APPLICATION_ID == '1000000251') {
    const Vendbox = {
      apiKey: 'AIzaSyAfNp6oDgpJTakSJWYwXLCHNVVm_cSBjTA',
      authDomain: 'sv-vendbox.firebaseapp.com',
      projectId: 'sv-vendbox',
      storageBucket: 'sv-vendbox.appspot.com',
      messagingSenderId: '146306495963',
      appId: '1:146306495963:web:28b59c203776cda9fc23fe',
      measurementId: 'G-H5XEM9LHQH',
    };

    initBaseFirebase(Vendbox);
  } else if (APPLICATION_ID == '1000000253') {
    const BeMart = {
      apiKey: 'AIzaSyDLWmZp_sGY6DV5hTqfvtsrUG0E2A3NUqg',
      authDomain: 'sv-bemart.firebaseapp.com',
      projectId: 'sv-bemart',
      storageBucket: 'sv-bemart.appspot.com',
      messagingSenderId: '544773660943',
      appId: '1:544773660943:web:84fbd27b7a4f3a85705e7a',
      measurementId: 'G-E661RM9Q6Q',
    };

    initBaseFirebase(BeMart);
  } else if (APPLICATION_ID == '1000000258') {
    const PerfectHealth = {
      apiKey: 'AIzaSyAR-VQJskW1r_fN7RuJvadU02VcGyqxb6c',
      authDomain: 'sv-perfect-health.firebaseapp.com',
      projectId: 'sv-perfect-health',
      storageBucket: 'sv-perfect-health.appspot.com',
      messagingSenderId: '671832702154',
      appId: '1:671832702154:web:378367259f01cd2b134833',
      measurementId: 'G-X6SJR0P9MB',
    };

    initBaseFirebase(PerfectHealth);
  } else if (APPLICATION_ID == '1000000260') {
    const DSN = {
      apiKey: 'AIzaSyCkQVf0D-ebUZW7fgoeDwRmpxlFrnMlFp4',
      authDomain: 'sv-dsn.firebaseapp.com',
      projectId: 'sv-dsn',
      storageBucket: 'sv-dsn.appspot.com',
      messagingSenderId: '908802657565',
      appId: '1:908802657565:web:03b24986056f27f352a5f4',
      measurementId: 'G-NVL56FTL3X',
    };

    initBaseFirebase(DSN);
  } else if (APPLICATION_ID == '1000000254') {
    const Monstermart = {
      apiKey: 'AIzaSyCTQ5X4Ua_zCWYkN6Vu6j5zS49jJVKh_Oc',
      authDomain: 'sv-monstermart.firebaseapp.com',
      projectId: 'sv-monstermart',
      storageBucket: 'sv-monstermart.appspot.com',
      messagingSenderId: '209318373979',
      appId: '1:209318373979:web:975e6b97623a1615996d7e',
      measurementId: 'G-71CE7G53PG',
    };

    initBaseFirebase(Monstermart);
  } else if (APPLICATION_ID == '1000000261') {
    const BemartNS = {
      apiKey: 'AIzaSyAemeWCzv6tPBLe2Et2lo5he-t_fgWyKps',
      authDomain: 'sv-bemart-nipponsekai.firebaseapp.com',
      projectId: 'sv-bemart-nipponsekai',
      storageBucket: 'sv-bemart-nipponsekai.appspot.com',
      messagingSenderId: '493307759198',
      appId: '1:493307759198:web:85cd7c995c9bcd32e4c2cf',
      measurementId: 'G-SBVF4Y1PEE',
    };
    initBaseFirebase(BemartNS);
  } else if (APPLICATION_ID == '1000000263') {
    const Jogjawa = {
      apiKey: 'AIzaSyBZYJPTYFv7WEWK26rY9nBdHnXIfkihbh8',
      authDomain: 'sv-jogjawasupati.firebaseapp.com',
      projectId: 'sv-jogjawasupati',
      storageBucket: 'sv-jogjawasupati.appspot.com',
      messagingSenderId: '5938082519',
      appId: '1:5938082519:web:0b6515b022514efb203692',
      measurementId: 'G-LZPBHQX8NE',
    };
    initBaseFirebase(Jogjawa);
  }
};
