/* eslint-disable no-self-assign */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
//Import Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb';
import Transaction from '../../../Component/transactionsTable';
import { isEmpty, size } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ReactTooltip from 'react-tooltip';
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Spinner,
  InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import {
  list as listApp,
  deviceList,
  create,
} from '../../store/payment-link/actions';
//Import Breadcrumb
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import {
  list_business_points,
  list,
  list_vm,
  download,
  //   read,
  //   updateRefund,
  //   refunded,
} from '../../store/transaction-admin/actions';

import { PROJECT_HANDLE, REFUND_PERIOD } from 'Apps';
import toastr from 'toastr';
const paymentOptions = [
  { label: 'All transactions', value: 'all' },
  { label: 'Settlement', value: 'settlement' },
  { label: 'Refund Pending', value: 'refund_pending' },
  { label: 'Timeout', value: 'timeout' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Failure', value: 'failure' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Outstanding', value: 'outstanding' },
  { label: 'Credit', value: 'credit' },
  { label: 'Debt', value: 'debt' },
];
const bankOptions = [
  { label: 'All', value: 'all' },
  { label: 'CASH', value: 'CASH' },
  { label: 'ECR', value: 'ECR' },
  { label: 'RFID', value: 'RFID' },
  { label: 'QRIS-MIDTRANS', value: 'QRIS-MIDTRANS' },
  { label: 'QRIS-MIDTRANS-PARTNER', value: 'QRIS-MIDTRANS-PARTNER' },
  { label: 'QRIS-BANKNOBU', value: 'QRIS-BANKNOBU' },
];
const dispenseOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'No Dispense', value: 'no_dispense' },
  { label: 'Success', value: 'success' },
  { label: 'Forced Dispense', value: 'forced_dispense' },
  { label: 'No Dispense VM TimeOut', value: 'no_dispense_vm_timeout' },
  {
    label: 'No Dispense Column Unresponsive',
    value: 'no_dispense_column_unresponsive',
  },
  { label: 'No Dispense VM error', value: 'no_dispense_vm_error' },
  { label: '-', value: '-' },
];

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();

    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let trx = params.get('trx_status');
    let dsp = params.get('dsp_status');
    let bank = params.get('bank_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));

    let businessPoints = { label: 'All points', value: 'all' };
    let businessPointsDownload = { label: 'All Point', value: 'all' };
    let transactionStatus = { value: 'all' };
    let transactionStatusDownload = { value: 'all' };
    let dispenseStatus = { value: 'all' };
    let dispenseStatusDownload = { value: 'all' };
    let paymentDownload = { value: 'all' };
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if (trx != undefined || trx != null) {
      transactionStatus = { value: trx };
    }
    if (dsp != undefined || dsp != null) {
      dispenseStatus = { value: dsp };
    }
    if (bank != undefined || bank != null) {
      paymentDownload = { value: bank };
    }
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;

    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - REFUND_PERIOD);
    let endDate = new Date();
    let arrDate = [newDate, endDate];
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
    } else {
      arrDate = [newDate, endDate];
    }
    this.state = {
      businessPointsDownload: businessPointsDownload,
      modal: false,
      modalDetail: false,
      products: [],
      trx: [],
      confirm_msg: false,
      confirm_alert: false,
      bpfilterlist: [],
      appList: [],
      dateRange: arrDate,
      dateRangeDownload: arrDate,
      role: '',
      anchor: false,
      businessPoints: businessPoints,
      paymentDownload: paymentDownload,
      transactionStatus: transactionStatus,
      transactionStatusDownload: transactionStatusDownload,
      dispenseStatus: dispenseStatus,
      dispenseStatusDownload: dispenseStatusDownload,
      dispenseOptions: dispenseOptions,
      bankOptions: bankOptions,
      registered_Option: [],
      vm_Option: [],
      TransactionColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          dataField: 'detail.order_id',
          text: 'Order',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <Link
                to={
                  '/admin/transaction/detail/' +
                  safeDeepGet(row, ['detail', 'order_id'])
                }
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
                className="mb-2"
              >
                {safeDeepGet(row, ['detail', 'order_id'])}
              </Link>
              <p className="mb-0"></p>
              <p className="mb-1">{this.handleValidDate(row.time.timestamp)}</p>
            </>
          ),
        },
        {
          dataField: 'detail.project_name',
          text: 'Project Name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.detail.project_name}</p>
            </>
          ),
        },
        {
          dataField: 'notes',
          text: 'Type',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="mb-1">{row.detail.notes}</p>
            </>
          ),
        },
        {
          dataField: 'total',
          text: 'Total',
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
              'refunded' ? (
                <p style={{ color: 'red', marginTop: 10, textAlign: 'right' }}>
                  {'-' +
                    Number(
                      safeDeepGet(
                        row,
                        ['detail', 'refund_amount'],
                        safeDeepGet(row, ['payment', 'amount'], '0')
                      )
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </p>
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
                  'settlement' ||
                safeDeepGet(row, ['detail', 'transaction_status'], '-') ===
                  'outstanding' ? (
                <p
                  style={{ color: 'green', marginTop: 15, textAlign: 'right' }}
                >
                  {'+' +
                    Number(
                      safeDeepGet(
                        row,
                        ['payment', 'nett'],
                        safeDeepGet(row, ['payment', 'amount'], '0')
                      )
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </p>
              ) : (
                <p style={{ marginTop: 15, textAlign: 'right' }}>
                  {Number(
                    safeDeepGet(
                      row,
                      ['payment', 'nett'],
                      safeDeepGet(row, ['payment', 'amount'], '0')
                    )
                  )
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </p>
              )}
            </>
          ),
        },
        {
          dataField: 'paymentStatus',
          text: 'Payment Status',
          formatter: (cellContent, row) => (
            <>
              <Tippy content={safeDeepGet(row, ['payment', 'method'], '-')}>
                <span
                  style={{ border: 'none' }}
                  className={
                    'font-size-12 badge badge-pill badge-soft-' +
                    safeDeepGet(row, 'badgeclass', 'info')
                  }
                  color={safeDeepGet(row, 'badgeclass', 'info')}
                >
                  {safeDeepGet(row, ['detail', 'transaction_status'], '-')}
                </span>
              </Tippy>
            </>
          ),
        },
        {
          dataField: 'detail',
          text: 'Detail',
          formatter: (cellContent, row) => (
            <Button color="primary" onClick={() => this.handleDetail(row)}>
              Detail
            </Button>
          ),
        },
      ],
    };

    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handleTransactionStatusDownload =
      this.handleTransactionStatusDownload.bind(this);
    this.handlePaymentMethodDownload =
      this.handlePaymentMethodDownload.bind(this);
    this.handleDispenseStatusDownload =
      this.handleDispenseStatusDownload.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleDateRangeDownload = this.handleDateRangeDownload.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBusinessPointsDownload =
      this.handleBusinessPointsDownload.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.handleOnchange = this.handleOnchange.bind(this);
    this.handleDownloadFile = this.handleDownloadFile.bind(this);
  }
  handleDownloadFile() {
    const {
      selectedAppId,
      businessPointsDownload,
      paymentDownload,
      transactionStatusDownload,
      dispenseStatusDownload,
      dateRangeDownload,
    } = this.state;
    const payload = {
      application_id: selectedAppId,
      businessPoints: businessPointsDownload.value,
      bank: paymentDownload.value,
      transactionStatus: transactionStatusDownload.value,
      dispenseStatus: dispenseStatusDownload.value,
      dateRange: dateRangeDownload,
    };
    this.setState({ loading: true });
    this.props.DownloadData(payload);
  }

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  // untuk ke Payment Server
  handleDetail = arg => {
    const trx = arg;

    // this.toggleDetail();
    this.props.history.push(
      `/admin/transaction/detail/${safeDeepGet(
        trx,
        ['detail', 'order_id'],
        'default'
      )}`
    );
  };
  handleClick() {
    this.setState({
      drp_secondary_sm: !this.state.drp_secondary_sm,
    });
  }
  handleClose = () => {
    this.setState(prevState => ({
      anchor: !prevState.anchor,
    }));
  };
  handleRefundDate = date => {
    if (date) {
      const d = new Date(0, 0, 0, 0, 0, 0, date).toLocaleDateString('en-GB');
      return d;
    }
  };
  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
    };
    this.props.LoadTransactionList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/admin/transaction',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.value);
      this.props.history.push({
        pathname: '/admin/transaction',
        search: searchParams.toString(),
      });
    }
  };
  handleBusinessPointsDownload = businessPointsDownload => {
    this.setState({ businessPointsDownload });
  };
  handlePaymentMethodDownload = paymentDownload => {
    this.setState({ paymentDownload });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props;
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ orders: {}, isEdit: false });
    }
    if (this.props.listResult !== prevProps.listResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['listResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['listResult', 'body'], {});
        this.setState({ appList: body });
      } else if (code === Result.FAILED) {
        this.showToast('Failed Get List Application', 'error');
      }
    }
    if (this.props.vm_result !== prevProps.vm_result) {
      const code = safeDeepGet(
        this.props,
        ['vm_result', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const vm_Option = safeDeepGet(this.props, ['vm_result', 'body'], []);
        this.setState({ vm_Option });
      }
    }
    if (this.props.downloadResult !== prevProps.downloadResult) {
      const code = safeDeepGet(
        this.props,
        ['downloadResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        this.setState({ loading: false });
        this.showToast('Success Download Transactions', 'success');
      } else if (code === Result.FAILED) {
        console.log(this.props.downloadResult);
        this.showToast('Failed to Download Transactions', 'error');
      }
    }
  }
  showToast(message, toastType) {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  handleTransactionStatusDownload = transactionStatusDownload => {
    this.setState({ transactionStatusDownload });
  };
  handleTransactionStatus = transactionStatus => {
    this.setState({ transactionStatus });
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
    };
    this.props.LoadTransactionList(payload);

    var searchParams = new URLSearchParams(window.location.search);
    if (transactionStatus.value == 'all') {
      searchParams.delete('trx_status');
      this.props.history.push({
        pathname: '/admin/transaction',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('trx_status', transactionStatus.value);
      this.props.history.push({
        pathname: '/admin/transaction',
        search: searchParams.toString(),
      });
    }
  };

  handleDispenseStatusDownload = dispenseStatusDownload => {
    this.setState({ dispenseStatusDownload });
  };

  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRange });
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
      };
      this.props.LoadTransactionList(payload);
      var searchParams = new URLSearchParams(window.location.search);
      if (dateRange[0] == null || dateRange[0] == undefined) {
        searchParams.delete('dateStart');
        searchParams.delete('dateEnd');
        this.props.history.push({
          pathname: '/admin/transaction',
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('dateStart', dateRange[0].getTime());
        searchParams.set('dateEnd', dateRange[1].getTime());
        this.props.history.push({
          pathname: '/admin/transaction',
          search: searchParams.toString(),
        });
      }
    }
  };
  handleDateRangeDownload = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      this.setState({ dateRangeDownload: dateRange });
    }
  };

  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    let newDate = new Date(year, month, day - REFUND_PERIOD);
    let endDate = new Date();
    this.setState({ dateRange: [newDate, endDate] });

    var current_month = new Date();
    this.props.ListApplicationId();
    this.props.LoadBpFilterList();
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
    };
    this.props.LoadTransactionList(payload);
    // setInterval(async () => {
    //   this.props.LoadTransactionList(payload);
    // }, 3000);
    // Load transactions
  }

  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { orders } = this.props;
    this.setState({
      orders: orders.filter(order =>
        Object.keys(order).some(
          key =>
            typeof order[key] === 'string' &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    });
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteOrder = order => {
    const { onDeleteOrder } = this.props;
    if (order.id !== undefined) {
      onDeleteOrder(order);
      this.onPaginationPageChange(1);
    }
  };

  handleValidDate = date => {
    // const date1 = date.toDate().toLocaleDateString('en-GB');
    // const time1 = date.toDate().toLocaleTimeString('en-GB');
    const date1 = new Date(date).toLocaleDateString('en-GB');
    const time1 = new Date(date).toLocaleTimeString('en-GB');

    return date1 + ' ' + time1;
  };

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };
  handleOnchange(value) {
    this.setState({ selectedAppId: value.value });
    this.props.LoadBusinessPointVM(value.value);
  }

  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      for (const el in list) {
        trx_data = {
          order_id: safeDeepGet(list[el], ['detail', 'order_id'], '-'),
          business_point: safeDeepGet(
            list[el],
            ['detail', 'project_name'],
            '-'
          ),
          column: safeDeepGet(list[el], ['product', 'column'], '-'),
          date: this.handleValidDate(
            safeDeepGet(list[el], ['time', 'timestamp'], '-')
          ),
          sku: safeDeepGet(
            list[el],
            'nameProduct',
            safeDeepGet(list[el], 'sku', '-')
          ),
          total: safeDeepGet(list[el], ['payment', 'amount'], 0),
          method: safeDeepGet(list[el], ['payment', 'method'], '-'),
          issuer: safeDeepGet(list[el], ['payment', 'detail', 'issuer'], '-'),
          payment_status: safeDeepGet(
            list[el],
            ['detail', 'transaction_status'],
            '-'
          ),
          dispense_status: safeDeepGet(
            list[el],
            ['detail', 'dispense_status'],
            '-'
          ),
        };
        dataSet.push(trx_data);
      }
      return dataSet;
    }
  };

  render() {
    // TODO Loading
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );

    let {
      dispenseStatusDownload,
      role,
      refundStatus,
      appList,
      selectedAppId,
      dispenseOptions,
      bankOptions,
      dateRangeDownload,
      businessPointsDownload,
      registered_Option,
      vm_Option,
      paymentDownload,
    } = this.state;
    let list = [];
    let {
      dateRange,
      businessPoints,
      transactionStatus,
      dispenseStatus,
      payment,
    } = this.state;
    {
      PROJECT_HANDLE == 'Nestle' ||
      PROJECT_HANDLE == 'Franke' ||
      PROJECT_HANDLE == 'Indesso'
        ? role == 'refiller'
          ? []
          : (list = safeDeepGet(this.props, ['result', 'body'], []))
        : (list = safeDeepGet(this.props, ['result', 'body'], []));
    }

    // const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    //pagination customization
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(Order),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id',
        order: 'desc',
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };
    const report_list = this.format_report(list);
    let report_filename = '';
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    if (dateRange[1] == null) {
      var endDate = new Date();
      var startDate = new Date();
      startDate.setDate(1);
      report_filename =
        'TrxAdmin' +
        bp_name +
        ' ' +
        startDate.toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB');
    } else {
      report_filename =
        'TrxAdmin ' +
        bp_name +
        ' ' +
        dateRange[0].toLocaleDateString('en-GB') +
        ' ke ' +
        dateRange[1].toLocaleDateString('en-GB');
    }
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let trx = params.get('trx_status');
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }
    if (trx != undefined || trx != null) {
      transactionStatus = trx;
    } else {
      transactionStatus = transactionStatus.value;
    }
    let dsp = params.get('dsp_status');
    let bank = params.get('bank_status');
    if (dsp != undefined || dsp != null) {
      dispenseStatus = dsp;
    } else {
      dispenseStatus = dispenseStatus.value;
    }

    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Transaction List | Admin</title>
          </MetaTags>

          <Container fluid>
            <Breadcrumbs title="Transaction" breadcrumbItem="List" />
            <Row>
              <Col xs="12">
                {/* {this.state.code === 'rfid' ? (
                  <TransactionRFID />
                ) : (
                  <Transaction />
                )} */}
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="id"
                      columns={this.state.TransactionColumns || []}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={list}
                          columns={this.state.TransactionColumns || []}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="2">
                                  <FormGroup className="mb-3 ajax-select select2-container">
                                    <Select
                                      defaultValue={bpfilterlist[0]}
                                      value={bpfilterlist.filter(function (
                                        option
                                      ) {
                                        return option.value === businessPoints;
                                      })}
                                      onChange={this.handleBusinessPoints}
                                      options={bpfilterlist}
                                      classNamePrefix="select2-selection"
                                      isLoading={false}
                                      placeholder="Business Point"
                                    />
                                  </FormGroup>
                                </Col>

                                <Col sm="6">
                                  <Row>
                                    <Col sm="4">
                                      <Label className="col-md-12 col-form-label text-sm-end">
                                        Date Range
                                      </Label>
                                    </Col>
                                    <Col sm="8">
                                      <FormGroup className="mb-3 ajax-select select2-container">
                                        <InputGroup>
                                          <Flatpickr
                                            className="form-control d-block"
                                            placeholder="dd M,yyyy"
                                            options={{
                                              maxDate: new Date(),
                                              mode: 'range',
                                              dateFormat: 'Y-m-d',
                                            }}
                                            value={dateRange}
                                            onChange={this.handleDateRange}
                                            ref={this.refDatePicker}
                                          />
                                          {/* <Button
                                  type="reset"
                                  color="secondary"
                                  onClick={this.handleClearDateRange}
                                >
                                  Clear
                                </Button> */}
                                        </InputGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  sm="2"
                                  className="d-none d-lg-block"
                                  align="center"
                                >
                                  <div className="clearfix mt-4 mt-lg-0">
                                    <button
                                      type="button"
                                      onClick={this.toggle}
                                      className="btn btn-primary w-sm"
                                    >
                                      <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                      Download Project
                                    </button>
                                  </div>
                                </Col>

                                <Col
                                  sm="2"
                                  className="d-none d-lg-block"
                                  align="center"
                                >
                                  <div className="clearfix mt-4 mt-lg-0">
                                    <ExcelFile
                                      filename={report_filename}
                                      element={
                                        <button
                                          type="button"
                                          className="btn btn-primary w-sm"
                                          disabled={
                                            list.length == 0 ? true : false
                                          }
                                        >
                                          <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                          Download Excel
                                        </button>
                                      }
                                    >
                                      <ExcelSheet
                                        data={report_list}
                                        name="Transaction List"
                                      >
                                        <ExcelColumn
                                          label="Order Id"
                                          value="order_id"
                                        />
                                        <ExcelColumn
                                          label="Business Point"
                                          value="business_point"
                                        />
                                        <ExcelColumn
                                          label="Date"
                                          value="date"
                                        />
                                        <ExcelColumn
                                          label="Total"
                                          value="total"
                                        />
                                        <ExcelColumn
                                          label="Payment Method"
                                          value="method"
                                        />
                                        <ExcelColumn
                                          label="Payment Issuer"
                                          value="issuer"
                                        />
                                        <ExcelColumn
                                          label="Payment Status"
                                          value="payment_status"
                                        />
                                      </ExcelSheet>
                                    </ExcelFile>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <ReactTooltip place="top" effect="solid" />
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    'table align-middle table-nowrap table-check'
                                  }
                                  headerWrapperClasses={'table-light'}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              size="lg"
              isOpen={this.state.modal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggle} tag="h4">
                {'Download List Transaction Project'}
              </ModalHeader>
              <ModalBody>
                <AvForm>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Row>
                              <Row>
                                <Col sm="12">
                                  <Label htmlFor="applicationId">
                                    Project Name:
                                  </Label>
                                  <Select
                                    id="applicationId"
                                    name="applicationId"
                                    options={appList}
                                    className="mb-3"
                                    maxMenuHeight={300}
                                    placeholder="Select Project Name"
                                    onChange={this.handleOnchange}
                                    value={appList.filter(function (option) {
                                      return option.value === selectedAppId;
                                    })}
                                  ></Select>
                                </Col>
                              </Row>
                              {vm_Option.length > 0 ? (
                                <>
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <FormGroup className="mb-3 ajax-select select2-container">
                                        <Select
                                          defaultValue={vm_Option[0]}
                                          // value={vm_Option.filter(function (
                                          //   option
                                          // ) {
                                          //   return (
                                          //     option === businessPointsDownload
                                          //   );
                                          // })}
                                          onChange={
                                            this.handleBusinessPointsDownload
                                          }
                                          options={vm_Option}
                                          classNamePrefix="select2-selection"
                                          isLoading={false}
                                          placeholder="Business Point"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                      <FormGroup className="mb-3 ajax-select select2-container">
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          defaultValue={paymentOptions[0]}
                                          isSearchable={false}
                                          onChange={
                                            this.handleTransactionStatusDownload
                                          }
                                          // value={paymentOptions.filter(
                                          //   function (option) {
                                          //     return (
                                          //       option.value ===
                                          //       transactionStatus
                                          //     );
                                          //   }
                                          // )}
                                          name="color"
                                          options={paymentOptions}
                                          placeholder="Payment Status"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                      <FormGroup className="mb-3 ajax-select select2-container">
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          defaultValue={bankOptions[0]}
                                          isSearchable={false}
                                          onChange={
                                            this.handlePaymentMethodDownload
                                          }
                                          // value={bankOptions.filter(function (
                                          //   option
                                          // ) {
                                          //   return (
                                          //     option.value === paymentDownload
                                          //   );
                                          // })}
                                          name="color"
                                          options={bankOptions}
                                          placeholder="Payment Method"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="2" align="center"></Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col sm="6">
                                      <FormGroup className="mb-3 ajax-select select2-container">
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          defaultValue={dispenseOptions[0]}
                                          isSearchable={false}
                                          onChange={
                                            this.handleDispenseStatusDownload
                                          }
                                          name="color"
                                          options={dispenseOptions}
                                          placeholder="Dispense Status"
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col sm="6">
                                      <Row>
                                        <Col sm="4">
                                          <Label className="col-md-12 col-form-label text-sm-end">
                                            Date Range
                                          </Label>
                                        </Col>
                                        <Col sm="8">
                                          <FormGroup className="mb-3 ajax-select select2-container">
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="dd M,yyyy"
                                                options={{
                                                  // maxDate: new Date(),
                                                  mode: 'range',
                                                  dateFormat: 'Y-m-d',
                                                }}
                                                value={dateRangeDownload}
                                                onChange={
                                                  this.handleDateRangeDownload
                                                }
                                                // ref={this.refDatePicker}
                                                ref={flatpickr =>
                                                  (this.flatpickrInstance =
                                                    flatpickr &&
                                                    flatpickr.flatpickr)
                                                }
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                            </Row>
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <div className="d-flex flex-wrap gap-2 float-end mb-4">
                        {selectedAppId != null ? (
                          <Link
                            className="btn btn-primary text-center"
                            role="button"
                            to="#"
                            onClick={this.handleDownloadFile}
                            disabled={this.state.loading} // Disable the button while loading
                            style={{
                              pointerEvents: this.state.loading
                                ? 'none'
                                : 'auto',
                            }} // Optional: disable pointer events when loading
                          >
                            {this.state.loading ? (
                              <Spinner size="sm">Loading...</Spinner>
                            ) : (
                              'Download'
                            )}
                          </Link>
                        ) : (
                          <></>
                        )}

                        <Link
                          className="btn btn-secondary"
                          role="button"
                          to="#"
                          onClick={this.toggle}
                        >
                          Batal
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  listResult: safeDeepGet(state, ['PaymentLink', 'list'], {}),
  bpfilterresult: safeDeepGet(
    state,
    ['transaction_admin', 'list_business_points'],
    {}
  ),
  result: safeDeepGet(state, ['transaction_admin', 'list'], {}),
  downloadResult: safeDeepGet(state, ['transaction_admin', 'download'], {}),
  loading: safeDeepGet(state, ['transaction_admin', 'loading'], true),
  vm_result: safeDeepGet(state, ['transaction_admin', 'list_vm'], {}),
  test: state,
});
Transaction.propTypes = {
  onRefund: PropTypes.func,
};
const mapDispatchToProps = dispatch => ({
  ListApplicationId: () => dispatch(listApp()),
  LoadBusinessPointVM: appId => dispatch(list_vm(appId)),
  LoadBpFilterList: () => dispatch(list_business_points()),
  DownloadData: payload => dispatch(download(payload)),
  LoadTransactionList: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    data
  ) =>
    dispatch(
      list(businessPoints, transactionStatus, dispenseStatus, dateRange, data)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
