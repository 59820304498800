/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  CardTitle,
  ModalHeader,
  Table,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { NAME_PROJECT, buildTitle } from 'Apps';
//Import Breadcrumb
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';

//CSV File
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
// Redux
import { connect } from 'react-redux';
import { updatePlanogramFranke } from '../../store/business-point/actions';
import { readPlanogramFranke } from '../../store/business-point/actions';
import { CircularProgress } from '@material-ui/core';

class PlanogramList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    // let deviceId = null;
    const { data, type } = props;
    const deviceId = data;
    const deviceType = type;
    this.state = {
      deviceType,
      modalDetail: false,
      deviceId: deviceId,
      bpfilterlist: [],
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      file: '',
      array: [],
      isLoading: true,
      headerTable: '',
      valuesArray: '',
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  handleImportClick = () => {
    this.toggleDetail();
  };
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleOnChange = e => {
    const file = e.target.files[0];
    if (file.size > 5000) {
      this.setState({ file });
    } else {
      this.setState({ file });
    }
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        for (const i in results) {
          const valuesArray = results['data'];
          this.setState({ valuesArray });
        }
        const rowsArray = [];
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
        });
        this.setState({ rowsArray });
      }.bind(this),
    });
  };

  handleSubmit() {
    const payload = {
      planogram: this.state.valuesArray,
      deviceId: this.state.deviceId,
      device_type: this.state.deviceType,
    };
    this.props.UpdatePlanogramFranke(payload);
    this.setState({ valuesArray: [] });
  }
  showToastFailed() {
    const { isEdit } = this.state;
    var toastType = 'error';
    var message = 'Gagal Update Planogram';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;

    var toastType = 'success';
    var message = 'Berhasil Tambah CSV planogram';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update CSV planogram';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          this.showToast();
          this.componentDidMount();
          // this.props.history.replace('/ingredient/list');
          // window.location.reload();
          this.handleImportClick();
        } else {
          this.showToastFailed();
        }
      }
    }
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const history = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'planogram'],
          []
        );
        const name = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'name'],
          []
        );
        const timeStamp = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'time_stamp'],
          0
        );
        const user = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'user'],
          []
        );
        this.setState({
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          isLoading: false
        });
      }
    }
  }
  handledDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  render() {
    // TODO Loading
    const loading = this.props.loading;
    const defaultSorted = [
      {
        dataField: 'year', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];
    const formValues = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'planogram'],
      []
    );

    const arr_data = formValues;
    const headerKeys = Object.keys(Object.assign({}, ...arr_data));
    const dataheader = {};
    const header = [];
    for (const id in headerKeys) {
      const data = headerKeys[id];
      safeDeepSet(dataheader, [id, 'text'], data);
      safeDeepSet(dataheader, [id, 'dataField'], data);

      // set formatter text in table
      if (data == 'imgUrl') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.imgUrl}</p>
          </>
        ));
      }
      if (data == 'description') {
        safeDeepSet(dataheader, [id, 'formatter'], (cellContent, row) => (
          <>
            <p className="text-wrap">{row.description}</p>
          </>
        ));
      }
      header.push(dataheader[id]);
    }
    const nameBP = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'name'],
      ''
    );
    const headers = [
      {
        label: 'selection',
        key: 'selection',
      },
      {
        label: 'sku',
        key: 'sku',
      },
      {
        label: 'name',
        key: 'name',
      },
      {
        label: 'price',
        key: 'price',
      },
    ];
    const separator = ','; //if want csv File using commma change with (',') or something else
    const csvLink = {
      headers: headers,
      data: formValues,
      separator: separator,
      filename: 'planogram ' + nameBP + '.csv',
    };
    const { SearchBar } = Search;
    const pageOptions = {
      sizePerPage: 25, //Change if to much
      totalSize: arr_data.length, // replace later with size,
      custom: true,
    };
    const { history, name, timeStamp, user, isLoading } = this.state;
    const filename = name + '_' + '_' + this.handledDate(timeStamp);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Planogram')}</title>
          </MetaTags>
          <Container fluid>
            {loading ? (
              <CircularProgress />
            ) : (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs="10">
                          <div className="text-sm-end">
                            <CSVLink
                              {...csvLink}
                              className="btn btn-primary mb-3"
                            >
                              Export to CSV
                            </CSVLink>
                          </div>
                        </Col>
                        <Col xs="2">
                          <div className="text-sm-start">
                            <Button
                              className="btn btn-primary "
                              onClick={this.handleImportClick}
                            >
                              Import CSV
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {formValues.length == 0 ? null : (
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="selection"
                            columns={header}
                            data={formValues}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="selection"
                                columns={header}
                                data={formValues}
                                search
                              >
                                {toolkitprops => (
                                  <React.Fragment>
                                    <Row className="mb-2"></Row>
                                    <Row>
                                      <Col xl="12">
                                      {isLoading ? (
                        <div className="text-center">
                          <CircularProgress className="text-center"></CircularProgress>
                        </div>
                      ) : (
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              'table align-middle table-nowrap table-hover'
                                            }
                                            bordered={true}
                                            striped={true}
                                            responsive
                                            ref={this.node}
                                          />
                                        </div>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                        <Modal
                          size="lg"
                          isOpen={this.state.modalDetail}
                          className={this.props.className}
                        >
                          <ModalHeader toggle={this.toggleDetail} tag="h4">
                            {'Import Planogram'}
                          </ModalHeader>
                          <ModalBody>
                            <Row className="mb-3">
                              <Col xs="7">
                                <div className="text-sm-center">
                                  <input
                                    className="form-control"
                                    type="file"
                                    accept=".csv"
                                    id="csvFile"
                                    onChange={this.handleOnChange}
                                  ></input>
                                </div>
                              </Col>
                              <Col xs="5">
                                <div className="text-sm-center">
                                  {guest == 'off' ? (
                                    <Button
                                      className="btn btn-franke white "
                                      onClick={this.handleSubmit}
                                    >
                                      Import CSV
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </ModalBody>
                        </Modal>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h5">
                        Latest Changes Planogram by
                      </CardTitle>

                      <Row className="align-items-center">
                        <Col lg="9" className="align-self-center">
                          <div className="table-responsive">
                            <Table className="table align-middle table-nowrap table-hover mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Email</th>
                                  <th scope="col">Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{user}</td>
                                  <td>
                                    {timeStamp == null
                                      ? '-'
                                      : this.handledDate(timeStamp)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>

                        <Col
                          lg="3"
                          className="d-none d-lg-block"
                          align="center"
                        >
                          <div className="clearfix mt-4 mt-lg-0">
                            <ExcelFile
                              filename={filename}
                              element={
                                <button
                                  type="button"
                                  className="btn btn-primary w-sm"
                                >
                                  <i className="mdi mdi-download d-block font-size-16"></i>
                                  Download
                                </button>
                              }
                            >
                              <ExcelSheet
                                data={history}
                                name="Transaction List"
                              >
                                <ExcelColumn
                                  label="selection"
                                  value="selection"
                                  numFmt="0"
                                />
                                <ExcelColumn label="sku" value="sku" />
                                <ExcelColumn label="name" value="name" />
                                <ExcelColumn
                                  label="price"
                                  value="price"
                                  numFmt="0"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  createResult: safeDeepGet(
    state,
    ['businessPoint', 'updatePlanogramFranke'],
    {}
  ),
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramFranke'],
    {}
  ),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadPlanogram: id => dispatch(readPlanogramFranke(id)),
  UpdatePlanogramFranke: payload => dispatch(updatePlanogramFranke(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramList);
