/* eslint-disable semi */
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { Buffer } from 'buffer';
import { FIREBASE_URL, PLATFORM_URL } from 'Apps/index';
import { ENV_APPLICATION_ID } from 'Env';
import { buildWsParam, buildWsParamAdmin } from 'Apps/index';

export function device_list() {
  const param = buildWsParam('/device-get');

  return JGET(param);
}

export function account_bank_list() {
  const param = buildWsParam('/account-get');
  return JGET(param);
}

export function stockPlanogram(deviceId) {
  const param = buildWsParam('/sensors-get?device_id=' + deviceId);
  return JGET(param);
}
export function get_notifications() {
  const param = buildWsParam('/task-get');
  return JGET(param);
}
export function close_notification(task_id) {
  const body = { task_id: task_id };
  const param = buildWsParam('/task-close', body);
  return JPOST(param);
}
export function stockEmpty(value, operator) {
  const param = buildWsParam(
    '/stock-get?value=' + value + '&operator=' + operator
  );

  return JGET(param);
}

export function bank_list() {
  const param = buildWsParam('/bank-get');

  return JGET(param);
}

export function payout_list() {
  const param = buildWsParam('/payout-get');

  return JGET(param);
}

export function getBalance() {
  const param = {
    url: FIREBASE_URL + `/getBalances`,
  };
  return JGET(param);
}
export function BalanceAdmin() {
  const param = {
    url: FIREBASE_URL + `/getBalancesAdmin`,
  };
  return JGET(param);
}

export function device_map() {
  return device_list();
}

export function sensors(deviceId) {
  const param = buildWsParam('/sensors-get?device_id=' + deviceId);
  return JGET(param);
}

export function setConfig3(
  deviceId,
  ids,
  names,
  prices,
  actives,
  selections,
  stocks
) {
  const param = {
    url: PLATFORM_URL + ENV_APPLICATION_ID + '/set_config3',
    body: {
      device_id: deviceId,
      ids,
      names,
      prices,
      actives,
      selections,
      stocks,
    },
  };
  // console.log(param)
  return JPOST(param);
}
export function setProjectHandle(body) {
  const param = {
    url: 'https://asia-southeast2-iotera-vending-multitenancy.cloudfunctions.net/projectHandle',
    body,
  };
  console.log(param);
  return JPOST(param);
}
export function createdUser(email, password, url) {
  if (url !== undefined) {
    const body = {
      email: email,
      password: password,
    };
    const btoaBody = Buffer.from(
      decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
    ).toString('base64');
    const param = {
      url: url,
      body: {
        data: btoaBody,
      },
    };
    return JPOST(param);
  }
}

export function deletedUser(uid, url) {
  if (url !== undefined) {
    const body = { uid: uid };
    const btoaBody = Buffer.from(
      decodeURIComponent(encodeURIComponent(JSON.stringify(body)))
    ).toString('base64');
    const param = {
      url: url,
      body: {
        data: btoaBody,
      },
    };
    return JPOST(param);
  }
}
export function adminListUser(getUser) {
  if (getUser !== undefined) {
    const param = {
      url: getUser,
    };
    return JGET(param);
  }
}

export function getDeviceTags(app_id) {
  const param = {
    headers: {
      'Vending-Application-Id': app_id == 'Admin' ? '1000000021' : app_id,
    },
    url: 'https://app-smartvending.iotera.io' + '/device-payment',
  };
  return JGET(param);
}
export function getDevice(app_id) {
  const param = {
    headers: {
      'Vending-Application-Id': app_id == 'Admin' ? '1000000021' : app_id,
    },
    url: 'https://app-smartvending.iotera.io' + '/device-get',
  };
  return JGET(param);
}
export function createPaymentLink(body, app_id, deviceId, deviceToken) {
  // TODO PRODUCTION
    const url= 'https://pay.iotera.io/transaction/snap-link'
    // TODO DEV
  // const url= 'http://mik.iotera.io:50001/payment/transaction/payment-link'
  const param = {
    url:url,
    headers: {
      "Iotera-Payment-Application-Id": app_id,
      "Iotera-Payment-Device-Id": deviceId,
      "Iotera-Payment-Device-Token": deviceToken
    },
    body: body
  };
  return JPOST(param);
}

export function getToken(app_id, dev_id) {
  const param = {
    headers: {
      'Iotera-Payment-Application-Id': app_id,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
    url: 'https://pay.iotera.io/web/device/get',
    body:{
      "device_id": dev_id
    }
  };
  return JPOST(param);
}

export function getBpFee(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function getBp(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function createBpFee(url, body) {
  const param = {
    url: url,
    body: body,
  };
  return JPOST(param);
}
export function set_price(url, body) {
  const param = {
    url: url,
    body: body,
  };
  return JPOST(param);
}
export function set_price_toMap(body) {
  const param = {
    url: 'https://asia-southeast2-iotera-vending.cloudfunctions.net/setPricetoMap',
    body: body,
  };
  return JPOST(param);
}
export function deleteBpFee(url, body) {
  const param = {
    url: url,
    body: body,
  };
  return JPOST(param);
}
export function post(url, body, id, multitenancy) {
  const app_id = '100000' + id;
  if (id != null || id != undefined) {
    safeDeepSet(body, 'application_id', app_id);
    safeDeepSet(body, 'multitenancy', multitenancy);
  }
  const param = {
    url: url,
    body: body,
  };
  return JPOST(param);
}
export function get(url, id, multitenancy) {
  const app_id = '100000' + id;

  const param = {
    url: url,
    queryParams: {
      app_id,
      multitenancy,
    },
  };
  return JGET(param);
}
export function getBalanceAdmin(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function getSummary(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function getBussinessPoint(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}
export function getSummaryDevice(url, deviceId) {
  const param = {
    url: PLATFORM_URL + '?deviceId=' + deviceId,
  };
  return JGET(param);
}
export function update_time(
  url,
  deviceId,
  time,
  start_date,
  end_date,
  app_id,
  multitenancy
) {
  const param = {
    url: url,
    body: {
      device_id: deviceId,
      active_time: time,
      start_date: start_date,
      end_date: end_date,
      app_id: '100000' + app_id,
      multitenancy: multitenancy,
    },
  };
  // console.log(param)
  return JPOST(param);
}

export function getTags(url) {
  const param = {
    url: url,
  };
  return JGET(param);
}

export function createTags(url, payload) {
  const param = {
    url: url,
    body: payload,
  };
  return JPOST(param);
}
export function deleteTags(url, body) {
  const param = {
    url: url,
    body: body,
  };
  // console.log(param)
  return JPOST(param);
}

export function subscription_call(body) {
  const tags = safeDeepGet(body, 'tags', '');
  const start_date = safeDeepGet(body, 'start_date', 0);
  const end_date = safeDeepGet(body, 'end_date', 0);
  const url = safeDeepGet(body, 'url', '');
  const create_date = safeDeepGet(body, 'create_date', 0);
  const category = safeDeepGet(body, 'category', '');
  const param = {
    url: url,
    body: {
      tags: tags,
      start_date: start_date,
      end_date: end_date,
      category: category,
    },
  };
  if (category == 'create') {
    safeDeepSet(param, ['body', 'create_date'], create_date);
  }
  // console.log(param)
  return JPOST(param);
}

export function tags_serverless(app_id) {
  const param = buildWsParamAdmin('/tags-get', '100000' + app_id);
  return JGET(param);
}
export function device_list_admin(app_id) {
  const param = buildWsParamAdmin('/device-get', '100000' + app_id);
  return JGET(param);
}

export function create_Tags(app_id, payload) {
  const param = buildWsParamAdmin('/tags-create', '100000' + app_id, payload);
  return JPOST(param);
}
export function delete_Tags(app_id, payload) {
  const param = buildWsParamAdmin('/tags-delete', '100000' + app_id, payload);
  return JPOST(param);
}
